import { Button } from 'components/Button';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { MaintenanceDefault } from 'ressources/img/MaintenanceDefault';
import logo from 'ressources/img/logo.png';
import Auth from 'services/auth0';
import { getItem, LocalStorageKey, removeItem } from 'services/localStorageService';
import styled from 'styled-components';

interface IProps {}

const PasswordExpired = ({}: IProps) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);

    const email = useMemo(() => getItem(LocalStorageKey.EXPIRED_PASSWORD_EMAIL), []);

    useEffect(() => {
        removeItem(LocalStorageKey.EXPIRED_PASSWORD_EMAIL);
    }, []);

    const onClick = async () => {
        setLoading(true);
        try {
            await Auth.sendResetPasswordEmail({ email });
            setLoading(false);
        } catch (error) {
            console.error('Error sending reset password email:', error);
            setLoading(false);
        }
    };
    if (!email) return <Navigate to="/login" />;
    return (
        <>
            <Header>
                <img src={logo} width={114} alt="Foodi Services" />
            </Header>
            <Container>
                <ContainerText>
                    <Title>{t(`error:passwordExpired.title`)}</Title>
                    <Text>{t(`error:passwordExpired.description`)}</Text>
                    <Text>{t(`error:passwordExpired.hasBeenSent`)}</Text>
                    <StyledButton onClick={onClick} disabled={loading}>
                        {loading ? t(`error:passwordExpired.loading`) : t(`error:passwordExpired.resendEmail`)}
                    </StyledButton>
                    <Text>
                        <Text style={{ fontSize: 15 }}>
                            {t(`error:passwordExpired.inCaseOfProb`)}{' '}
                            <a style={{ color: '#0084FF' }} href="mailto:help@foodi.fr" color="red">
                                help@foodi.fr
                            </a>
                        </Text>
                    </Text>
                    <Text style={{ fontSize: 15 }}>{t(`error:passwordExpired.seeYouSoon`)}</Text>
                </ContainerText>
                <MaintenanceDefault />
            </Container>
        </>
    );
};

export default PasswordExpired;

const Header = styled.div`
    padding-top: 20px;
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    background-color: #1d2c39;
`;

const Title = styled.div`
    color: #1d2c39;
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 60px;
    font-weight: 600;
`;

const Text = styled.div`
    color: #1d2c39;
    font-weight: 400;
    font-size: 22px;
    margin-bottom: 10px;
`;

const Container = styled.div`
    padding-left: 150px;
    background-color: #f9f9f9;
    width: 100%;

    height: 100vh;
`;

const ContainerText = styled.div`
    align-items: flex-start;
`;

const StyledButton = styled(Button)`
    color: black;
    background-color: #f4b83a;
    text-transform: none;
    margin-top: 20px;
    margin-bottom: 20px; /* Added margin-bottom for spacing */
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 18px;
    &:hover {
        background-color: #e0a832;
    }
    &:active {
        background-color: #c89632;
    }
    &:disabled {
        background-color: #f4b83a;
        opacity: 0.6;
    }
`;
